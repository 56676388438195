import React from "react";
import "../header/Header.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { LiaAtomSolid } from "react-icons/lia";
import { PiWavesBold } from "react-icons/pi";
import { GiEarthAfricaEurope } from "react-icons/gi";
import { GiHeatHaze } from "react-icons/gi";
import an_img from'../../../../assets/mo-abdalmagid/herobanner__1.png'
import an_img2 from'../../../../assets/mo-abdalmagid/herobanner__2.png'
import an_img3 from'../../../../assets/mo-abdalmagid/herobanner__3.png'
import an_img4 from'../../../../assets/mo-abdalmagid/herobanner__4.png'
import homeimg from "../../../../assets/mo-abdalmagid/home-left.png";
import homeimg2 from "../../../../assets/mo-abdalmagid/en.png";
export const Header = () => {
  AOS.init();
  return (
    <>
      <section className="home">
      <div className="atoms">
          <img className="atom w-25 one" src="" alt="" srcSet={homeimg2} />
            {/* <PiWavesBold className="atom two"/>
            <GiEarthAfricaEurope className="atom three"/>
            <GiHeatHaze className="atom four"/> */}
            </div>
        <div  data-aos="fade-right" data-aos-duration="1000" className="home-left">
          <img alt="home img" srcSet={homeimg2} />
        </div>
        <div  data-aos="fade-left" data-aos-duration="1000" className="home-right">
          <h1>أبـدأ رحلتك في عالم الفيزياء مع منصة م / <span> محمد عبد المجيد</span></h1>
          <p>
            أقوي منصة تعليمية لمادة الفيزياء لطلبة الثانوية العامة والازهرية
          </p>
        </div>
      </section>
    </>
  );
};
