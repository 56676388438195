import React, { useEffect } from "react";
import "../Advert/advert.css";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { PiLampPendantFill } from "react-icons/pi";
import { IoBarChart } from "react-icons/io5";
import { FaBookReader } from "react-icons/fa";
import AOS from "aos";
import advimg from "../../../../assets/mo-abdalmagid/Group 157.png";
import advimg2 from "../../../../assets/mo-abdalmagid/prop1.5694c3f376acc9d84b21.gif";
import advimg3 from "../../../../assets/mo-abdalmagid/prop2.8e51e4f200e82ffff301.gif";
import advimg4 from "../../../../assets/mo-abdalmagid/prop4.5c90ca5dd027af2cedf9.gif";
export const Advert = () => {
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 600,
      easing: "ease-in-sine",
      delay: 100,
    });
  });
  return (
    <div className="advert">
      <div
        data-aos="fade-left"
        data-aos-duration="1000"
        className="advert-left"
      >
        <h1>منهج تعليمي متميز و اختبارات</h1>
        <p className="p-border">
          نهدف إلى تقديم تعليم ذو جودة عالية وشامل يغطي جميع جوانب التعلم. محتوى
          المنصة مبني على أسس علمية ومعايير دولية لضمان توفير أفضل تجربة تعليمية
          للطلاب.
        </p>
        <img alt="" srcSet={advimg} />
      </div>
      <div
        data-aos="fade-right"
        data-aos-duration="1000"
        className="advert-right"
      >
        <div className="adv">
            <img className="w-25" src="" alt="" srcSet={advimg2} />
                    <div className="text d-flex flex-column">
            <h3>شرح منهج الفيزياء كامل بالتفصيل</h3>
            <p>
              هنشرحلك المنهج كامل بالتفصيل و هتقدر تعيد المحاضرة عشان تفهم
              الدروس اكتر
            </p>
          </div>
        </div>
        <div className="adv twoo">
            <img className="w-25" src="" alt="" srcSet={advimg4} />
          <div className="text d-flex flex-column">
          <h3>امتحانات و تركات في المنهج  </h3>
          <p>امتحانات تفاعلية مستمرة عشان تعرف مستواك الدراسي</p>
        </div>
        </div>
        <div className="adv threee">
        <img className="w-25" src="" alt="" srcSet={advimg3} />
        <div className="text d-flex flex-column">
          <h3>مش هنسيب حاجه في المنهج غير و هنوضحهالك و نفهمالك</h3>
          <p>هنحل واجبات بعد كل محاضرة عشان نفهم اكتر و نتدرب على الامتحانات</p>
        </div>
        </div>
      </div>
    </div>
  );
};
