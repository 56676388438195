import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import config from '../../../config'
import { auth } from '../../../Context/AuthStore'
import Placholder from '../../../Components/Used/Placeholder'

export default function HomeWorkScore() {

    const [examsScore, setExamsScore] = useState(false)
    const { userAuth } = useContext(auth)
    const [currentPage, setcurrentPage] = useState(1)
    const [haveNext, sethaveNext] = useState(false)

    const move = (e) => {
        if(e.target.getAttribute('role') == 'next'){
            // console.log(currentPage)
            if(haveNext) {
                setcurrentPage((prev) => prev + 1)
            }
        }else {
            if(currentPage > 1) {
                setcurrentPage((prev) => prev - 1)
            }
        }
    }

    const getExamsScore = () => {
        setExamsScore(false)
        axios.get(`${config.basURL}exam/exam-results?page=${currentPage}`, {
            headers: {
                'Authorization': config.ApiKey,
                'auth': `Bearer ${userAuth}`
            }
        }).then((res) => {
            let next = res.data.next ? true :false; 
            sethaveNext(next)
            let data = res.data.results.filter((exam) => {
                return exam.quiz !== null;
            })
            setExamsScore(data)
        }).catch((err) => {
            console.log(err)
        })
    }

    function getHumanDate(d) {
        const date = new Date(d);
        const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
        return date.toLocaleDateString('ar', options);
    }

    useEffect(() => {
        getExamsScore()
    }, [userAuth , currentPage])

    return <>
        <div className='container exam-score-section'>
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">
                            التسلسل
                        </th>
                        <th scope="col">
                            أسم الامتحان
                        </th>
                        <th scope="col">
                            عدد الاسئلة
                        </th>
                        <th scope="col">
                            درجه الامتحان
                        </th>
                        <th scope="col">
                            النتيجة
                        </th>
                        <th scope="col">
                            عدد الاسئلة المحلولة
                        </th>
                        <th scope="col">
                            الاجابات
                        </th>
                        <th scope="col">
                            وقت بدأ
                            الامتحان
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        examsScore ?
                            examsScore.map(exam => <tr>
                                <td>{exam.id}</td>
                                <td>
                                    {exam.quiz.name}
                                </td>
                                <td>
                                    {exam.quiz.quiz_questions_count}
                                </td>
                                <td> {exam.quiz.quiz_degree} </td>
                                <td>{Math.floor(exam.degree / exam.quiz.quiz_degree * 100)} %</td>
                                <td className='score-bar'>
                                    <span>{exam.question_answer.length}</span>
                                    <div className='bar'>
                                        <span style={{ width: `${Math.floor(exam.question_answer.length / exam.quiz.quiz_questions_count * 100)}%` }}></span>
                                    </div>
                                </td>
                                {/* <td className='score-bar'>
                                    <span>49</span>
                                    <div className='bar'>
                                        <span></span>
                                    </div>
                                </td> */}
                                <td>
                                    <Link to={`/user-profile/show-exam-answers/${exam.id}/${exam.quiz.name}`}>
                                        عرض الاجابات
                                    </Link>
                                </td>
                                {/* <td>
                                    {
                                        exam.exam.is_many_times ? <Link className='retake' to={`/quiz/${exam.exam.course}/${exam.exam.id}`}> اعاده الامتحان </Link> : <p className='bg-danger'> الامتحان لمره واحده </p>
                                    }
                                </td> */}
                                <td>
                                    {getHumanDate(exam.created)}
                                </td>
                            </tr>)
                            : <tr>
                                <td colSpan={7}>
                                    <Placholder />
                                </td>
                            </tr>
                    }
                </tbody>
            </table>
            {
                examsScore && examsScore.length > 0 &&  <div className="next-prev">
                <button onClick={move} className={`${currentPage == 1 ? 'disabled' : ''}`} role='prev' disabled={currentPage == 1}> السابق </button>
                <button onClick={move} className={`${haveNext? '' : 'disabled'}`} role='next' disabled={haveNext ? false : true}> التالي </button>
            </div>
            }
           
        </div>
    </>
}
