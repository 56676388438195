import React from "react";
import "../about/about.css";
import AOS from "aos";
import aboutimg from "../../../../assets/mo-abdalmagid/abdelmagid.png";
import aboutimg2 from "../../../../assets/mo-abdalmagid/about_4.png";
import { IoIosCheckmarkCircle } from "react-icons/io";
export const About = () => {
  AOS.init();
  return (
    <div className="about">
      <div data-aos="fade-left" data-aos-duration="1000" className="about-left">
        <img className="banner" src="" alt="" srcSet={aboutimg2} />
        <img src="" alt="" srcSet={aboutimg} />
      </div>
      <div
        data-aos="fade-right"
        data-aos-duration="1000"
        className="about-right"
      >
        <h1>
          أقـوي منصة تـعليمية في <span className="marker">مـادة الفيزياء</span>
        </h1>
        <p>
          اهلا بيك في منصتنا التعليمية, احنا هنا عشان نساعدك توصل لكل المعلومات
          الي محتاجها بسهوله{" "}
        </p>
        <div className="prp">
          <IoIosCheckmarkCircle className="iconn" />
          <h4>تقييم مستوي الطالب شهريا</h4>
        </div>
        <div className="prp">
          <IoIosCheckmarkCircle className="iconn" />
          <h4>مراجعات وامتحانات شاملة</h4>
        </div>
        <div className="prp">
          <IoIosCheckmarkCircle className="iconn" />
          <h4>توفير اكبر قدر من الأسئلة</h4>
        </div>
      </div>
    </div>
  );
};
