import React from 'react'

export default function UpdateQuize() {
    return <>
        <div className="container-fluid dashboard-quizes-page p-5">
            <h1>
                تعديل الواجب
            </h1>
            <form className='create-new-quiz-form'>
                <h5 className='py-3'>
                    {/* كويز جديد */}
                </h5>
                <div className='row gap-3'>
                    <input type="text" className=" col-lg-3" name='name' placeholder='اسم الكويز'  />
                    <input type="text" className=" col-lg-3" name='success_rate' placeholder='درجه النجاح'  />
                    <input type="text" className=" col-lg-3" name='time' placeholder='مده الكويز'  />
                    <select name="pending" className=" col-lg-3" id="" >
                        <option value="true" > غير مفعل </option>
                        <option value="false"> مفعل </option>
                    </select>
                </div>
                <div className='py-3 d-flex'>
                    <input type="checkbox" name="is_mandatory" id='mandatory' className='ms-2' />
                    <label htmlFor="mandatory"> اجباري </label>
                </div>
                <button>
                    تعديل
                </button>
            </form>
        </div>
    </>
}
