import React, { useEffect, useRef } from 'react'
import Swal from 'sweetalert2';
import shaka from 'shaka-player';

export default function Video({ videoLink, firstTime, sendWatchTry, setIntervalID, setWatchTime, intervalID, sendWatchTime, avilabile, watchTime }) {

    const media = useRef()
    const videoPlayer = useRef()

    // const videoUrl = 'https://gohary-whats.s3.eu-north-1.amazonaws.com/3%D8%AB-%D8%A7%D9%84%D9%85%D8%AD%D8%A7%D8%B6%D8%B1%D8%A9-2.mp4/output.m3u8'

    // // video play handler
    // // record user watch time
    useEffect(() => {
        let hasLogged = false;
        let cumulativeTime = 0;
        let lastTime = 0;

        let handller = () => {
            // Calculate time watched since the last update
          if(videoPlayer.current){
            const deltaTime = videoPlayer.current.currentTime - lastTime;
            lastTime = videoPlayer.current.currentTime;

            // Only add positive deltas to the cumulative time (skip backwards seeks)
            if (deltaTime > 0) {
                cumulativeTime += deltaTime;
            }

            const duration = videoPlayer.current.duration;
            const targetTime = duration * 0.3;

            if (!hasLogged && cumulativeTime >= targetTime) {
                sendWatchTry()
                console.log("User has cumulatively watched 30% of the video.");
                hasLogged = true; // Ensure the log happens only once
            }
          }
        }

        const handleSeek = function () {
            if(videoPlayer.current){
                lastTime = videoPlayer.current.currentTime;
            }
        }

        // Update lastTime on seek (to handle when user manually changes the play position)

        if(videoPlayer.current){
            videoPlayer.current.addEventListener('timeupdate', handller)
        videoPlayer.current.addEventListener('seeking', handleSeek);
        }
        return () => {
           if(videoPlayer.current){
            videoPlayer.current.removeEventListener('timeupdate', handller)
            videoPlayer.current.removeEventListener('seeking', handleSeek)
           }
        }

    }, [firstTime, watchTime])

    // // // pause record user watch time
    useEffect(() => {
        const onVideoPause = () => {
            clearInterval(intervalID)
            sendWatchTime()
        }
        videoPlayer.current.addEventListener('pause', onVideoPause)
        return () => {
            try {
                videoPlayer.current.removeEventListener('pause', onVideoPause)
            } catch (err) {
                console.log(err)
            }
        }
    }, [intervalID, watchTime])

    // // // video end handler
    useEffect(() => {
        const onEndedVideo = () => {
            sendWatchTime();
            clearTimeout(intervalID);
        }
        videoPlayer.current.addEventListener('ended', onEndedVideo)
        return () => {
            try {
                videoPlayer.current && videoPlayer.current.removeEventListener('ended', onEndedVideo)
            } catch (err) {
                console.log(err)
            }
        }
    }, [watchTime])

    useEffect(() => {
        if (!avilabile) {
            clearInterval(intervalID)
            Swal.fire({
                text: 'انتهت مشاهداتك لهذا الدرس',
                icon: 'info'
            })
        }
    }, [avilabile])


    useEffect(() => {
        // Check if the video element is available
        if (videoPlayer.current) {
            // Initialize the Shaka Player
            const player = new shaka.Player(videoPlayer.current);

            // Attach event listeners for error handling
            player.addEventListener('error', (event) => {
                console.error('Error code', event.detail.code, 'object', event.detail);
            });

            // Load the video URL
            player
                .load(videoLink)
                .then(() => {
                    console.log('The video has been loaded successfully!');
                })
                .catch((error) => {
                    console.error('Error loading video:', error);
                });

            // Cleanup function to destroy the player on unmount
            return () => {
                player.destroy();
            };
        }
    }, [videoLink]);

    return <>
        {/* {
            avilabile ? <video ref={media} className='w-100 rounded' controls controlsList="nodownload">
                <source src={videoLink} type='video/mp4' />
            </video> : <div className="alert alert-info text-center">انتهت مشاهداتك للدرس</div>
        } */}
        {
            avilabile ? <>
            <video ref={videoPlayer} width="640" controls  className='w-100 rounded' controlsList="nodownload"></video>
            </> : <div className="alert alert-info text-center">انتهت مشاهداتك للدرس</div>
        }



    </>
}
