import React, { useEffect, useRef, useState } from "react";
import "./mo.css";
import AOS from "aos";
import teacher from "../../../assets/taha-assets/teacher-banner.png";
import banner1 from "../../../assets/gharabawy/banner1.png";
import banner2 from "../../../assets/gharabawy/banner2.png";
import banner3 from "../../../assets/gharabawy/banner3.png";
import ahmedSalah from "../../../assets/taha-assets/ahmed salah.jpeg";
import mohamedEraky from "../../../assets/taha-assets/mohamed eraky.jpeg";
import tahaSalah from "../../../assets/taha-assets/taha salah.jpg";
import circle from "../../../assets/gharabawy/circle.png";
import { Link, useNavigate } from "react-router-dom";
import config from "../../../config";
import axios from "axios";
import Placeholder from "../../../Components/Used/Placeholder";
import { Header } from "./header/Header";
import { About } from "./about/About";
import { Properties } from "./properties/Properties";
import { Advert } from "./Advert/Advert";

export default function Mohome() {
  AOS.init();
  const navigate = useNavigate();
  const [allCourses, setAllCourses] = useState(false);
  const [displayCourses, setDisplayCourses] = useState(false);
  const [filter, setFilter] = useState(3);
  const circleElement = useRef();

  let navigateToCourses = (id) => {
    navigate(`/courses?year=${id}`);
  };

  const getCourses = () => {
    axios
      .get(`${config.basURL}course/course-list`, {
        headers: {
          Authorization: config.ApiKey,
        },
      })
      .then((res) => {
        console.log(res.data);
        let data = res.data.courses;
        setAllCourses(data);
        let init = data.filter((course, index) => {
          if (index < 6) {
            return course.year.id == filter;
          }
        });
        setDisplayCourses(init);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const selectAction = (e) => {
    // remove select class from all elements
    let elements = circleElement.current.children;
    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.remove("active");
    }
    // add selected class to selected element
    e.target.className = "active";
    let year = e.target.getAttribute("data-id");
    let init = allCourses.filter((course, index) => {
      if (index < 6) {
        return course.year.id == year;
      }
    });
    setDisplayCourses(init);
  };

  useEffect(() => {
    getCourses();
  }, []);

  return (
    <>
      {/* home */}
      <Header />
      {/* start about section */}
      <About />
      {/* features */}
      {/* <Properties /> */}
      {/*advert*/}
      <Advert />

      {/* // start courses section */}
      <section className="py-5 taha-courses">
        <div className="container">
          <h4 className="text-center fs-3 mb-5">
            كورسات المهندس / محمد عبد المجيد{" "}
          </h4>
          <h4 className="text-center fs-3 mb-5">#تريند_الفيزياء</h4>
          <ul className="course-filter" ref={circleElement}>
            <li
              className={`${filter === 1 && "active"}`}
              onClick={selectAction}
              data-id="1"
            >
              {" "}
              الصف الاول الثانوي{" "}
            </li>
            <li
              className={`${filter === 2 && "active"}`}
              onClick={selectAction}
              data-id="2"
            >
              {" "}
              الصف الثاني الثانوي{" "}
            </li>
            <li
              className={`${filter === 3 && "active"}`}
              onClick={selectAction}
              data-id="3"
            >
              {" "}
              الصف الثالث الثانوي{" "}
            </li>
          </ul>
          <div
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-duration="1200"
            data-aos-delay="50"
            className="row  gap-2 mb-2"
          >
            {displayCourses ? (
              displayCourses.length > 0 ? (
                displayCourses.map((course) => (
                  <div className="col-lg-3 course " key={course.id}>
                    <div className="image">
                      <img
                        src={`${course.cover}`}
                        alt=""
                        className="w-100 h-100"
                      />
                    </div>
                    <div className="body">
                      <div className="name-price">
                        <h6> {course.name} </h6>
                        <p className="price m-0">
                          {" "}
                          {course.free ? "مجاني" : `ج ${course.price}`}{" "}
                        </p>
                      </div>
                      <p> {course.about} </p>
                    </div>
                    <div className="content">
                      <p className="units">
                        <i className="fa-solid fa-shapes"></i>
                        {course.course_units.length}
                        الوحدات
                      </p>
                      <p className="lessons">
                        <i className="fa-solid fa-book-open-reader"></i>
                        {course.lessons_count}
                        الدروس
                      </p>
                      <p className="exams">
                        <i className="fa-solid fa-file"></i>
                        {course.course_file.length}
                        الفايلات
                      </p>
                    </div>
                    <div className="course-footer">
                      <Link to={`course-preview/${course.id}`}>
                        الذهاب للكورس{" "}
                        <div>
                          <i className="fa-solid fa-chevron-left"></i>
                        </div>
                      </Link>
                    </div>
                  </div>
                ))
              ) : (
                <p className="col-12 text-center"> لا يوجد كورسات حتي الان </p>
              )
            ) : (
              <Placeholder />
            )}
          </div>
        </div>
      </section>
    </>
  );
}
